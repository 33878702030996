const theme = {
  color: {
    black: '#000000',
    gray: '#171717',
    pink: '#FE3092',
    blue: '#004DFF',
    white: '#F2F2F2',
    oceanBlue: '#202637',
    red: '#ef0d33',
  },
  radius: {
    basic: '5px',
    button: '50px',
  },
  shadow: {
    basic: '0 0 5rem -1.3rem rgb(150, 150, 150)',
  },
  fontSize: {
    small: '1.2rem',
    normal: '1.4rem',
    medium: '1.6rem',
    large: '1.8rem',
    xLarge: '2rem',
    xxLarge: '2.4rem',
  },
}

export default theme
