import React from 'react'
import { H1 } from '../../components/Typography'

const Private = () => {
  return (
    <div>
      <H1>Private page</H1>
      <div>
        <strong>Only for login person</strong>
      </div>
    </div>
  )
}

export default Private
